import React from 'react'
import styled from 'styled-components'
import { breakMd } from '../constants'

export const Grid = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${breakMd}px) {
    flex-direction: column;
  }
`

const ItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.w ? `${props.w * (100 / 12)}%` : null)};
  @media (max-width: ${breakMd}px) {
    flex: 1;
    width: 100%;
  }
`

const ItemInner = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Item = ({ children, ...other }) => (
  <ItemOuter {...other}>
    <ItemInner>{children}</ItemInner>
  </ItemOuter>
)
