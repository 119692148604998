import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Item, Row as GridRow } from '../components/flexGrid'
import Image from '../components/image'

const Content = styled.div`
  text-transform: uppercase;
  font-size: 2em;
`

const Row = styled(GridRow)`
  margin-bottom: 3.5em;
`

const HomePage = ({ pageContext }) => {
  const { news } = pageContext
  return (
    <Grid>
      {news.map(({ image, content }, idx) => (
        <Row key={idx}>
          <Item w={image ? 7 : 12}>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          </Item>

          {!!image && (
            <Item w={5}>
              <Image src={image} />
            </Item>
          )}
        </Row>
      ))}
    </Grid>
  )
}

HomePage.propTypes = {
  pageContext: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
}

export default HomePage
